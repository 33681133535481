<template>
  <!-- Récolte -->
  <div class="tabs-material-subtitle">
    <Container>
      <div class="tabs-material-subtitle--button">
        <h2>Conditions de récolte</h2>

        <Btn
          color="primary"
          hollow
          text="Exporter la fiche"
          @click="download('pdf', 'evenement', $route.params.tid)"
        />
      </div>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Matériel récolte 1"
      :value="fiche?.materielrecolte1"
    />
    <KeyValue
      table
      label="Matériel récolte 2"
      :value="fiche?.materielrecolte2"
    />
    <KeyValue
      table
      label="Commentaires"
      :value="fiche?.commentaires"
    />
    <KeyValue
      table
      label="Conditions de récolte"
      :value="fiche?.conditions_recolte?.valeur"
    />
  </div>
</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ConditionsRecolteSubview',
  components: {
    Container,
    Btn,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fiche: {},
    }
  },

  emits: ['set-editable'],

  mounted() {
    this.getFiche()
  },

  methods: {
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'recolte')
    },
    download(type, ressource, rid) {
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/conditions-recolte`
      const filename = `evenement_${rid}_conditions_recolte`
      this.fileService.downloadFile(endpoint, filename, type)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-material-subtitle--button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
